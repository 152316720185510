import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Card, CardBody, Col, Input, Row } from 'reactstrap';
import { environment } from '../../enviroment/enviroment';

export default function ManageMultipleMatchingCriterias({ employerId, features, title }) {
  const matchingCriteriaDictionary = {
    id_number: 'ID Number',
    employee_no: 'Employee Number',
    bank_account_number: 'Bank Account Number',
    first_name: 'First Name',
    last_name: 'Last Name',
  };
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [configuration, setConfiguration] = useState({
    configurations: features.map((feature) => ({
      feature_name: feature.name,
      configuration: null
    })),
    combine: true
  });

  const getFeatureConfiguration = useCallback((feature_name) => {
    axios.get(`${environment.baseUrl}/employer_features_config/feature_configuration/?employer_id=${employerId}&feature_name=${feature_name}`)
      .then((res) => res.data)
      .then((data) => {
        setConfiguration((prev) => ({
          ...prev,
          configurations: prev.configurations.map((config) => {
            if (config.feature_name === feature_name) {
              return {
                ...config,
                configuration: data
              }
            }
            return config;
          })
        }))
        return data;
      })
      .catch((error) => {
        setError(`Failed to fetch employer ${feature_name} configuration`);
        return null;
      });
  }, [configuration.configurations, employerId]);

  const showAlert = useCallback((title, text, icon) => {
    Swal.fire({
      title,
      text,
      icon,
      confirmButtonText: 'Ok',
    });
  }, []);

  const updateMatchingCriterias = async () => {
    setLoading(true);
    let count = 0;
    const errors = [];
    configuration.configurations.forEach(async (config) => {
      await axios.put(`${environment.baseUrl}/employer_features_config/${config.configuration.id}/update_feature_configuration/`, {
        configuration: config.configuration.configuration
      })
        .then((res) => res.data)
        .then((data) => {
          count++;
          if (count === configuration.configurations.length && errors.length === 0) {
            showAlert('Success', `${title} Matching criteria updated successfully`, 'success');
            setLoading(false);
          } else if (count === configuration.configurations.length && errors.length > 0) {
            showAlert('Error', errors.join(', '), 'error');
            setLoading(false);
          }
        })
        .catch((error) => {
          count++;
          errors.push("Failed to update " + config.feature_name + " configuration"); 
          if (count === configuration.configurations.length && errors.length > 0) {
            showAlert('Error', errors.join(', '), 'error');
            setLoading(false);
          }
        })
    })
  }

  const toggleField = useCallback((field, feature_name) => {
    
    const config = configuration.configurations.find((config) => config.feature_name === feature_name);
    let fields = config.configuration.configuration.matching_criteria.split(',');
    if (fields.includes(field)) {
      fields = fields.filter((f) => f !== field);
    } else {
      fields.push(field);
    }

    if (fields.length === 0) {
      setError('At least one matching criteria must be selected');
      return;
    }

    if (configuration.combine) {
      setConfiguration((prev) => ({
        ...prev,
        configurations: prev.configurations.map((config) => {
          return {
            ...config,
            configuration: {
              ...config.configuration,
              configuration: {
                ...config.configuration.configuration,
                matching_criteria: fields.join(','),
              }
            }
          }
        })}))
    } else {
      setConfiguration((prev) => ({
        ...prev,
        configurations: prev.configurations.map((config) => {
          if (config.feature_name === feature_name) {
            return {
              ...config,
              configuration: {
                ...config.configuration,
                configuration: {
                  ...config.configuration.configuration,
                  matching_criteria: fields.join(','),
                }
              }
            }
          }
          return config;
        })}))
    }

  }, [configuration.configurations, configuration.combine]);


  const updateCombinedCriteria = async (checked) => {
    if (!checked) {
      Swal.fire({
        title: 'Warning',
        text: `Please note that changing will reset all your matching criteria to match the ${configuration.configurations[0].feature_name} configuration`,
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        showCancelButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          setConfiguration((prev) => ({
            ...prev,
            combine: !checked,
            configurations: prev.configurations.map((config) => {
              return {
                ...config,
                configuration: {
                  ...config.configuration,
                  configuration: {
                    ...config.configuration.configuration,
                    matching_criteria: prev.configurations[0].configuration.configuration.matching_criteria
                  }
                }
              }
            })
          }));
        }
      })
    } else {
      setConfiguration((prev) => ({
        ...configuration,
        combine: !checked
      }));
    }
  }

  useEffect(() => {
    setLoading(configuration.configurations.filter((config) => !config.configuration).lenth === 0 && !error);

    const configurations = configuration.configurations.map((config) => config.configuration)?.map((config) => config?.configuration).filter((config) => config);
    let isCombined = true;
    configurations.forEach((config, index) => {
      if (index > 0 && config.matching_criteria !== configurations[index - 1].matching_criteria) {
        isCombined = false;
      }
    })
    if (configurations.length > 0 && !isCombined) {
      setConfiguration((prev) => ({
        ...prev,
        combine: isCombined
      }))
    }
    
  }, [configuration.configurations]);

  useEffect(() => {
    setLoading(true);
    features.forEach((feature) => {
      getFeatureConfiguration(feature.name);
    });
  }, [employerId]);
  
  return (
    <Card className="shadow" style={{ marginTop: '20px' }}>
      <CardBody>
        <div>
          <h3>{title}</h3>
        </div>

        {!loading && configuration.configurations.length > 1 && (
          <Row>
            <Col lg={10}>
              Do you want to match your {title.toLowerCase()} using different identifiers?
            </Col>
            <Col lg={2}>
              <Input
                type="checkbox"
                checked={!configuration.combine}
                onChange={(e) => updateCombinedCriteria(e.target.checked)}
              />
            </Col>
          </Row>
        )}

        {loading && (
          <div className="spinner-border text-primary m-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <hr />

        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        {configuration.configurations.filter((config) => !config.configuration).length === 0 && (
          <div>
            {configuration.configurations.map((config, index) => (
              <div key={index}>
                {!configuration.combine && <h3>{config.feature_name}</h3>}

                {index > 0 && configuration.combine ? null : (
                  <>
                    <div className="form-group">
                      {Object.keys(matchingCriteriaDictionary).map((key, index) => (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={config.configuration.configuration.matching_criteria
                              .split(',')
                              .includes(key)}
                            value=""
                            onChange={(e) => toggleField(key, config.feature_name)}
                            id={`${config.configuration.id}${index}`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`${config.configuration.id}${index}`}>
                            {matchingCriteriaDictionary[key]}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="form-group">
                      <label htmlFor="matching_criteria">Document length</label>
                      <select
                        className="form-control"
                        id="matching_criteria"
                        value={config.configuration.configuration.document_length}
                        onChange={(e) =>
                          setConfiguration((prev) => ({
                            ...prev,
                            configurations: prev.configurations.map((prevConfig) => {
                              if (prevConfig.feature_name === config.feature_name) {
                                return {
                                  ...prevConfig,
                                  configuration: {
                                    ...prevConfig.configuration,
                                    configuration: {
                                      ...prevConfig.configuration.configuration,
                                      document_length: parseInt(e.target.value),
                                    },
                                  },
                                };
                              }
                              return prevConfig;
                            }),
                          }))
                        }
                      >
                        {Array.from(Array(5).keys()).map((key, index) => (
                          <option key={index} value={key + 1}>
                            {key + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            ))}
            <button
              disabled={loading}
              onClick={updateMatchingCriterias}
              className="btn btn-primary btn-block">
              {loading ? 'Loading...' : 'Save Changes'}
            </button>
          </div>
        )}

      </CardBody>
    </Card>
  )
}
