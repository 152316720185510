import React from 'react';
import axios from 'axios';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Link } from 'react-router-dom';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';

const REASONS = {
  1: 'Transport',
  2: 'Groceries',
  3: 'Bills',
  4: 'Family/Kids',
  5: 'Shopping/entertainment',
  6: 'Emergency medical/repair expense',
  7: 'Other',
};

class AdvanceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getAdvanceRequest();
    this.getEmployees();
  }

  getAdvanceRequest = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${environment.baseUrl}/advancerequests/${this.props.match.params.advId}/`
      )
      .then((res) => {
        this.setState({
          ...res.data,
          loading: false,
        });
      });
  };

  getEmployees = () => {
    axios
      .get(`${environment.baseUrl}/employees/${this.props.match.params.advId}/`)
      .then((res) => {
        this.setState({
          first_name: res.data.first_name,
        });
      });
  };

  saveData = () => {
    const uuid = this.props.match.params.advId;
    axios
      .patch(`${environment.baseUrl}/advancerequests/${uuid}/`, {
        status: this.state.status,
      })
      .then((res) => {
        Swal.fire({
          title: 'Advance request saved',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  handleinputchange = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const {
      loading,
      employee,
      amount_requested,
      date_created,
      reason,
      fee,
      status,
      source_type,
      instant_payment,
      uuid,
    } = this.state;
    return (
      <section className="dashboard">
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Advance Request
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container fluid className="mt--7">
          {loading ? (
            <Row className="my-3">
              <Col className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="mt-5">
              <Col>
                <Card className="shadow">
                  <CardHeader>Details</CardHeader>
                  <CardBody>
                    <Row className="mb-4">
                      <Col>
                        <div className="h5" style={{ fontWeight: 400 }}>
                          Employee
                        </div>
                        <div className="h3">
                          {employee && (
                            <Link to={`/admin/employees/${employee.id}`}>
                              {employee.first_name} {employee.last_name}
                            </Link>
                          )}
                        </div>
                      </Col>
                      <Col>
                        <div className="h5" style={{ fontWeight: 400 }}>
                          Employer
                        </div>
                        <div className="h3">
                          {employee.employer.company_name}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>
                        <div className="h5" style={{ fontWeight: 400 }}>
                          Amount
                        </div>
                        <div className="h3">{`R${amount_requested}`}</div>
                      </Col>
                      <Col>
                        <div className="h5" style={{ fontWeight: 400 }}>
                          Fee
                        </div>
                        <div className="h3">{`R${fee}`}</div>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>
                        <div className="h5" style={{ fontWeight: 400 }}>
                          Reason
                        </div>
                        <div className="h3">
                          {[1, 2, 3, 4, 5, 6, 7].includes(reason) &&
                            `${REASONS[reason]}`}
                        </div>
                      </Col>
                      <Col>
                        <div className="h5" style={{ fontWeight: 400 }}>
                          Date requested
                        </div>
                        <div className="h3">
                          {moment(date_created).format(
                            'MMMM Do YYYY, h:mm:ss a'
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>
                        <div className="h5" style={{ fontWeight: 400 }}>
                          Instant Payment
                        </div>
                        <div className="h3">
                          {instant_payment ? 'Yes' : 'No'}
                        </div>
                      </Col>
                      <Col>
                        <div className="h5" style={{ fontWeight: 400 }}>
                          Source
                        </div>
                        <div className="h3">{source_type}</div>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>
                        <div className="h5" style={{ fontWeight: 400 }}>
                          UUID
                        </div>
                        <div className="h3">{uuid}</div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className="shadow">
                  <CardHeader>Edit</CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="h5" style={{ fontWeight: 400 }}>
                          Current status
                        </div>
                        <div className="h3">{status}</div>
                      </Col>
                      <Col>
                        <h5>Changes status to</h5>
                        <select
                          className="form-control"
                          name="status"
                          onChange={this.handleinputchange}
                          required
                        >
                          <option
                            value="pending"
                            selected={status === 'pending'}
                          >
                            Pending
                          </option>
                          <option
                            value="rejected"
                            selected={status === 'rejected'}
                          >
                            Rejected
                          </option>
                          <option value="paid" selected={status === 'paid'}>
                            Paid
                          </option>
                        </select>
                      </Col>
                    </Row>
                    <div
                      className="row data-save pt-5"
                      style={{ float: 'right' }}
                    >
                      <div className="col addbtn">
                        <button
                          onClick={this.saveData}
                          className="btn btn-primary"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    );
  }
}

export default AdvanceDetail;
