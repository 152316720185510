import React from 'react';
import './styles.css';

const InputToggle = (props) => {
  const {
    text,
    size = 'default',
    checked,
    disabled,
    onChange,
    offstyle = 'btn-danger',
    onstyle = 'btn-success',
  } = props;

  const displayStyle = checked ? onstyle : offstyle;
  return (
    <label>
      <span className={`${size} switch-wrapper`}>
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={(e) => onChange(e)}
        />
        <span className={`${displayStyle} switch`}>
          <span className="switch-handle" />
        </span>
      </span>
      <span className="switch-label">{text}</span>
    </label>
  );
};

export default InputToggle;
