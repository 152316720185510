import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody } from 'reactstrap';
import Swal from 'sweetalert2';
import { environment } from '../../enviroment/enviroment';

export default function ManageMatchingCriteria({ documentType, employerId }) {
  const [loading, setLoading] = useState(true);
  const matchingCriteriaDictionary = {
    id_number: 'ID Number',
    employee_no: 'Employee Number',
    bank_account_number: 'Bank Account Number',
    first_name: 'First Name',
    last_name: 'Last Name',
  };
  const [configuration, setConfiguration] = useState(null);
  const [error, setError] = useState(null);

  const toggleField = useCallback(
    (field) => {
      setError(null);
      let fields = configuration.configuration.matching_criteria.split(',');
      if (fields.includes(field)) {
        fields = fields.filter((f) => f !== field);
      } else {
        fields.push(field);
      }
      if (fields.length === 0) {
        setError('At least one matching criteria must be selected');
        return;
      }

      setConfiguration({
        ...configuration,
        configuration: {
          ...configuration.configuration,
          matching_criteria: fields.join(','),
        },
      });
    },
    [configuration]
  );

  const updateCriteria = async () => {
    setLoading(true);
    axios
      .put(
        `${environment.baseUrl}/employer_features_config/${configuration.id}/update_feature_configuration/`,
        {
          configuration: configuration.configuration,
        }
      )
      .then((res) => {
        setLoading(false);
        Swal.fire({
          title: 'Success',
          text: `${documentType} Matching criteria updated successfully`,
          icon: 'success',
          confirmButtonText: 'Ok',
        });
      })
      .catch((error) => {
        setLoading(false);
        setError('Failed to update matching criteria');
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${environment.baseUrl}/employer_features_config/feature_configuration/?employer_id=${employerId}&feature_name=${documentType}`
      )
      .then((res) => res.data)
      .then((data) => {
        setConfiguration(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(`Failed to fetch employer ${documentType} configuration`);
      });
  }, [documentType, employerId]);

  return (
    <Card className="shadow" style={{ marginTop: '20px' }}>
      <CardBody>
        <label>{documentType} Matching Criteria</label>
        <hr />
        {loading && (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        {configuration && (
          <div>
            <div className="form-group">
              {Object.keys(matchingCriteriaDictionary).map((key, index) => (
                <div className="form-check" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={configuration.configuration.matching_criteria
                      .split(',')
                      .includes(key)}
                    value=""
                    onChange={(e) => toggleField(key)}
                    id={`defaultCheck${index}`}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`defaultCheck${index}`}>
                    {matchingCriteriaDictionary[key]}
                  </label>
                </div>
              ))}
            </div>
            <div className="form-group">
              <label htmlFor="matching_criteria">Document length</label>
              <select
                className="form-control"
                id="matching_criteria"
                value={configuration.configuration.document_length}
                onChange={(e) =>
                  setConfiguration({
                    ...configuration,
                    configuration: {
                      ...configuration.configuration,
                      document_length: parseInt(e.target.value),
                    },
                  })
                }>
                {Array.from(Array(5).keys()).map((key, index) => (
                  <option key={index} value={key + 1}>
                    {key + 1}
                  </option>
                ))}
              </select>
            </div>
            <button
              disabled={loading}
              onClick={updateCriteria}
              className="btn btn-primary btn-block">
              {loading ? 'Loading...' : 'Save Changes'}
            </button>
          </div>
        )}
      </CardBody>
    </Card>
  );
}
