import React, { createContext, useReducer } from 'react';
import axios from 'axios';
import { environment } from '../enviroment/enviroment';

const initialState = {
  advances: 0,
  registrations: 0,
  user: JSON.parse(localStorage.getItem('USER')),
};

const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ADVANCES':
      return {
        ...state,
        advances: action.payload,
      };
    case 'SET_RISKY_ADVANCES':
      return {
        ...state,
        risky_advances: action.payload,
      };
    case 'SET_REGISTRATIONS':
      return {
        ...state,
        registrations: action.payload,
      };
    case 'SET_SUPPORT_TICKETS':
      return {
        ...state,
        supportTickets: action.payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

const GlobalStore = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  const fetchStats = async () => {
    axios.get(`${environment.baseUrl}/stats/sidebar/`).then((res) => {
      dispatch({ type: 'SET_ADVANCES', payload: res.data.advances });
      dispatch({
        type: 'SET_RISKY_ADVANCES',
        payload: res.data.risky_advances,
      });
      dispatch({ type: 'SET_REGISTRATIONS', payload: res.data.registrations });
      dispatch({
        type: 'SET_SUPPORT_TICKETS',
        payload: res.data.support_tickets,
      });
    });
  };

  const logInUser = (user) => {
    localStorage.setItem('USER', JSON.stringify(user));
    dispatch({ type: 'SET_USER', payload: { ...user } });
  };

  const logOutUser = () => {
    localStorage.clear();
    dispatch({ type: 'SET_USER', payload: null });
  };

  return (
    <Context.Provider value={{ state, fetchStats, logInUser, logOutUser }}>
      {children}
    </Context.Provider>
  );
};

export const Context = createContext(initialState);
export default GlobalStore;
