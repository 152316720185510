import React from 'react';
import { Card, Col, Row } from 'reactstrap';

const Pagination = ({ page, setPage, pages }) => (
  <Row>
    <Col>
      <Card className="p-3">
        <nav
          aria-label="Page navigation example"
          style={{ display: 'flex', justifyContent: 'center' }}>
          <ul className="pagination pagination-lg">
            {page > 1 && (
              <li className="page-item">
                <div className="page-link" aria-label="Previous">
                  <i className="fa fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </div>
              </li>
            )}
            {page > 1 && (
              <li className="page-item">
                <div className="page-link" onClick={() => setPage(page - 1)}>
                  {page - 1}
                </div>
              </li>
            )}
            <li className="page-item active">
              <div className="page-link">{page && `${page}`}</div>
            </li>
            {page < pages && (
              <li className="page-item">
                <div className="page-link" onClick={() => setPage(page + 1)}>
                  {page + 1}
                </div>
              </li>
            )}
            {page < pages && (
              <li className="page-item">
                <div className="page-link" aria-label="Next">
                  <i className="fa fa-angle-right" />
                  <span className="sr-only">Next</span>
                </div>
              </li>
            )}
          </ul>
        </nav>
      </Card>
    </Col>
  </Row>
);

export default Pagination;
