import React from 'react';
import { Table, Container } from 'reactstrap';
import moment from 'moment';
import { environment } from '../../../enviroment/enviroment';

const SubscriptionBatchList = (props) => {
  const { subscriptionBatches, handleShowBatchItemsModal } = props;

  return (
    <Container fluid>
      <div className="card">
        <div className="card-body">
          {Array.isArray(subscriptionBatches) && subscriptionBatches.length ? (
            <Table striped>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Employer</th>
                  <th>Subscription</th>
                  <th>Date Range</th>
                  <th>Requested By</th>
                  <th>Date Created</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionBatches.map((batch) => (
                  <tr key={batch.id}>
                    <td>{batch.id}</td>
                    <td>{batch.employer.companyName}</td>
                    <td>{batch.subscription.name}</td>
                    <td>
                      {`${moment(batch.startDate).format(
                        'DD/MM/Y, h:mm a',
                      )} - ${moment(batch.endDate).format('DD/MM/Y, h:mm a')}`}
                    </td>
                    <td>{batch.requestedBy.username}</td>
                    <td>
                      {moment(batch.dateCreated).format(
                        'MMMM Do YYYY, h:mm:ss a',
                      )}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm "
                        onClick={() => handleShowBatchItemsModal(batch.id)}
                      >
                        View Subscriptions
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() =>
                          window.open(
                            `${environment.baseUrl}/subscription_batch/${batch.id}`,
                          )
                        }
                      >
                        Download CSV
                      </button>
                    </td>
                  </tr>
                ))}{' '}
              </tbody>
            </Table>
          ) : (
            <div>No subscription batches </div>
          )}
        </div>
      </div>
    </Container>
  );
};

SubscriptionBatchList.propTypes = {};

SubscriptionBatchList.defaultProps = {
  advanceBatches: [],
  handleShowBatchItemsModal: () => {},
};

export default SubscriptionBatchList;
